var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{model:{value:(_vm.chooseActiveName),callback:function ($$v) {_vm.chooseActiveName=$$v},expression:"chooseActiveName"}},[_c('el-tab-pane',{attrs:{"label":"院校分数","name":"first"}},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index + 'school'},[_c('div',{staticClass:"smalltitle"},[_c('div',{staticClass:"titleline"}),_c('div',{staticClass:"titletext"},[_vm._v("院校录取数据("+_vm._s(item.label)+")")])]),_c('div',{staticClass:"tabel"},[_c('el-tabs',{model:{value:(item.activeName),callback:function ($$v) {_vm.$set(item, "activeName", $$v)},expression:"item.activeName"}},_vm._l((item.schooltableData),function(item1,index1){return _c('el-tab-pane',{key:index1,attrs:{"label":item1.collegeName,"name":item1.universityId}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":item1.data,"stripe":"","header-cell-style":{
                  'text-align': 'center',
                  'font-size': '14px',
                  color: '#333333',
                },"cell-style":{ 'text-align': 'center', color: '#666666' }}},[_c('el-table-column',{attrs:{"prop":"year","label":"年份"}}),_c('el-table-column',{attrs:{"prop":"admitBatch","label":"批次"}}),_c('el-table-column',{attrs:{"prop":"scoreLowest","label":"最低分"}}),_c('el-table-column',{attrs:{"label":"最高分"}},[[_c('div',{staticStyle:{"color":"#666666"}},[_vm._v("0")])]],2),_c('el-table-column',{attrs:{"prop":"actualNum","label":"招生数"}})],1)],1)}),1)],1)])}),0),_c('el-tab-pane',{attrs:{"label":"专业分数","name":"second"}},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index + 'specialty'},[_c('div',{staticClass:"smalltitle"},[_c('div',{staticClass:"titleline"}),_c('div',{staticClass:"titletext"},[_vm._v("专业录取数据（"+_vm._s(item.label)+"）")])]),_c('div',{staticClass:"tabel"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":item.specialtytableData,"stripe":"","header-cell-style":{
              'text-align': 'center',
              'font-size': '14px',
              color: '#333333',
            },"cell-style":{ 'text-align': 'center', color: '#666666' }}},[_c('el-table-column',{attrs:{"prop":"year","label":"年份"}}),_c('el-table-column',{attrs:{"prop":"admitBatch","label":"批次"}}),_c('el-table-column',{attrs:{"prop":"scoreLowest","label":"最低分"}}),_c('el-table-column',{attrs:{"label":"最高分"}},[[_c('div',{staticStyle:{"color":"#666666"}},[_vm._v("0")])]],2),_c('el-table-column',{attrs:{"prop":"actualNum","label":"招生数"}})],1)],1)])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }