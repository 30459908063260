<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="1">
        <keep-alive>
          <BasicInformation v-if="activeName == 1" :id="universityId"></BasicInformation>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="师资力量" name="2">
        <div v-if="activeName == 2" v-html="schoolform.faculty" class="faculty">
        </div>
      </el-tab-pane>
      <el-tab-pane label="录取数据" name="3">
        <keep-alive>
          <AdmissionData v-if="activeName == 3 && isopen" :id="universityId" :name="schoolform.collegeName">
          </AdmissionData>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="学校排名" name="4">
        <keep-alive>
          <SchoolRankings v-if="activeName == 4" :form="dto"></SchoolRankings>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="国家特色专业" name="5">
        <div v-if="activeName == 5" v-html="schoolform.disciplineBuild" class="faculty">
        </div>
      </el-tab-pane>
      <el-tab-pane label="更多数据" name="6">
        <div v-if="activeName == 6" class="more">
          <div class="img">
            <img :src="QRcode[0]" alt="" style="width: 150px" />
          </div>
          <div class="imgtitle">更多信息请使用微信扫一扫前往OK志愿查看</div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BasicInformation from "./components/basicInformation";
import SchoolRankings from "./components/SchoolRankings";
import AdmissionData from "./components/AdmissionData";
import { getUniversityInfo } from "@/api/dataquery";
export default {
  components: {
    BasicInformation,//基本信息
    SchoolRankings,//院校排名
    AdmissionData//录取数据
  },
  data() {
    return {
      universityId: "",
      activeName: "1",
      schoolform: {},
      dto: {},
      QRcode: [],
      isopen: false,
    };
  },
  mounted() {
    this.universityId = this.$route.query.universityId;
    this.getUniversityInfo()
    this.getQRcode()
  },
  methods: {
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images || [];
      }
    },
    handleClick(tab, event) {
      if (tab.name == 3) {
        let sessionId = sessionStorage.getItem("sessionId") || null;
        if (sessionId) {
          this.isopen = true
        } else {
          this.$message({
            showClose: true,
            message: '请先登录',
            type: 'error'
          });
        }
      }
    },
    getUniversityInfo() {
      getUniversityInfo({ universityId: this.universityId }).then((res) => {
        if (res.code == 1) {
          this.schoolform = res.data || {};
          this.dto = { collegeName: this.schoolform.collegeName, universityId: this.schoolform.universityId }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
  color: #1ab394 !important;
}

/deep/.el-tabs__item:hover {
  color: #1ab394 !important;
}

/deep/.el-tabs__active-bar {
  background-color: #1ab394 !important;
}

.faculty {
  width: 100%;
  font-size: 14px;
  text-indent: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.more {
  margin-top: 30px;

  .imgtitle {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }

  .img {
    width: 150px;
    margin: 0px auto;
  }
}
</style>