<template>
  <div class="bigbox">
    <div class="header">
      <div style="width: 10%">
        <div class="img">
          <img style="width: 80px; height: 80px; border-radius: 40px" v-if="schoolform.collegeBadge"
            :src="'/com/file/fileDownLoad?filePath=' + schoolform.collegeBadge" alt="" />
          <img style="width: 80px; height: 80px; border-radius: 40px" v-else src="../../../../assets/find/校徽.png"
            alt="" />
        </div>
      </div>
      <div class="" style="width: 90%">
        <div class="schoolname">{{ schoolform.collegeName }}</div>
        <div class="bo">
          <span v-for="(item1, index1) in schoolform.collegeCharacter" :key="index1">
            <span v-if="item1 == 1" class="span span1">211</span>
            <span v-if="item1 == 2" class="span span2">985</span>
            <span v-if="item1 == 3" class="span span3">双一流</span>
          </span>
        </div>
        <div class="text">
          <span>主管部门：{{ schoolform.subordinate | subordinateFilter }}</span>
          <span>学历层次：{{ schoolform.education | educationFilter }} </span>
          <span>院校类型：{{ schoolform.disciplineAmbit | disciplineFilter }}
          </span>
          <span>办学性质：{{ schoolform.runType | runTypeFilter }} </span>
          <span>招生电话：{{ schoolform.recruitPhone }}</span>
        </div>
      </div>
    </div>
    <div class="content" v-html="schoolform.introduction"></div>
    <div class="smalltitle">
      <div class="titleline"></div>
      <div class="titletext">校园风景</div>
    </div>
    <div class="imglist">
      <img class="schoolimg" :src="item" alt="" v-for="(item, index) in schoolform.albumPictures" :key="index"
        @click="showimg(item)" />
    </div>
    <el-dialog title="校园风景" :visible.sync="dialogVisible" width="810px">
      <img :src="img" alt="" style="width: 770px; border-radius: 5px;" />
    </el-dialog>
  </div>
</template>

<script>
import { getUniversityInfo } from "@/api/dataquery";
export default {
  data() {
    return {
      universityId: "",
      schoolform: {},
      img: "",
      dialogVisible: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(n, o) {
        this.universityId = n;
        if (this.universityId) {
          this.getUniversityInfo();
        }
      },
      deep: true,
    },
  },
  filters: {
    colorFilter(data) {
      switch (data) {
        case 1:
          return "211";
        case 2:
          return "985";
        case 3:
          return "双一流";
        case 4:
          return "民办";
        case 5:
          return "公办";
        default:
          return data;
      }
    },
    disciplineFilter(data) {
      switch (data) {
        case 0:
          return "全部";
        case 1:
          return "综合";
        case 2:
          return "理工";
        case 3:
          return "文史";
        case 4:
          return "师范";
        case 5:
          return "教育";
        case 6:
          return "财经";
        case 7:
          return "艺术";
        case 8:
          return "农林";
        case 9:
          return "军事";
        case 10:
          return "民族";
        case 11:
          return "政法";
        case 12:
          return "医药";
        case 13:
          return "体育";
        case 14:
          return "预科";
        case 20:
          return "其他";
        default:
          return data;
      }
    },
    runTypeFilter(data) {
      switch (data) {
        case 0:
          return "全部";
        case 1:
          return "公办";
        case 2:
          return "民办";
        case 3:
          return "其他";
        default:
          return data;
      }
    },
    subordinateFilter(data) {
      switch (data) {
        case 1:
          return "教育部";
        case 2:
          return "公安部";
        case 3:
          return "工业和信息化部";
        case 4:
          return "国家科工委";
        case 5:
          return "国务院侨务办公室";
        case 6:
          return "教育厅";
        case 7:
          return "交通运输部";
        case 8:
          return "教育委员会";
        case 9:
          return "工业和信息化部";
        case 10:
          return "中央办公厅";
        case 11:
          return "国家卫生和计划生育委员会";
        case 12:
          return "外交部";
        case 13:
          return "国家体育总局";
        case 14:
          return "国家民族事务委员会";
        case 15:
          return "中华全国妇女联合会";
        case 16:
          return "中国共产主义青年团中央";
        case 17:
          return "中华全国总工会";
        case 18:
          return "中国科学院";
        case 19:
          return "司法部";
        case 20:
          return "中国地震局";
        case 21:
          return "国家安全生产监督管理总局";
        case 22:
          return "国家林业局";
        case 23:
          return "生产建设兵团";
        case 25:
          return "中科院";
        case 26:
          return "工信部";
        case 27:
          return "中国共产党中央军事委员会";
        case 28:
          return "国卫委";
        case 29:
          return "省政府";
        case 30:
          return "统战部";
        case 31:
          return "市政府";
        case 32:
          return "中国人民解放军海军";
        case 33:
          return "中国人民解放军空军";
        case 34:
          return "国民委";
        case 35:
          return "中国人民解放军战略支援部队";
        case 36:
          return "新疆维吾尔自治区";
        case 37:
          return "内蒙古自治区";
        case 38:
          return "国体局";
        case 39:
          return "广西壮族自治区";
        case 40:
          return "中国人民解放军陆军";
        case 41:
          return "交运部";
        case 42:
          return "新疆生产建设兵团";
        case 43:
          return "宁夏回族自治区";
        case 44:
          return "西藏自治区";
        case 45:
          return "湖北省教育厅";
        case 46:
          return "交通运输部（中国民用航空局";
        case 47:
          return "共青团";
        case 48:
          return "办公厅";
        case 49:
          return "应管部";
        case 50:
          return "总工会";
        case 51:
          return "地震局";
        case 52:
          return "省教育厅";
        case 53:
          return "妇联会";
        case 54:
          return "海关";
        case 55:
          return "北京市教育委员会";
        case 56:
          return "社科院";
        case 57:
          return "市教委";
        case 58:
          return "香港特别行政区政府教育局";
        case 59:
          return "中华人民共和国澳门特别行政区高等教育局";
        case 60:
          return "香港特别行政区教育局";
        case 61:
          return "中国人民解放军火箭军";
        case 62:
          return "香港特别行政区政府";
        case 63:
          return "上海市中国科学院";
        case 64:
          return "中国人民武装警察部队总部";
        case 65:
          return "省人民政府";
        case 66:
          return "国家军委";
        case 67:
          return "解放军战略支援部队航天系统部";
        case 68:
          return "澳门特别行政区政府";
        case 69:
          return "河南省体育局";
        case 70:
          return "中国人民武装警察部队";
        case 71:
          return "中央军委联合参谋部";
        case 72:
          return "中国人民解放军训练管理部";
        case 73:
          return "中国人民解放军空军总部";
        case 74:
          return "中国人民解放军总政治部";
        case 75:
          return "中国共产党中央军事委员会政治工作部";
        case 76:
          return "香港特别行政区政府高等教育署";
        case 77:
          return "中国人民解放军";
        case 78:
          return "中国人民解放军院校";
        case 79:
          return "澳门特别行政区社会文化司";
        case 80:
          return "宁夏自治区教育厅";
        case 81:
          return "四川省教育厅";
        case 82:
          return "广西壮族自治区教育厅";
        case 83:
          return "北京市交通委员会";
        case 84:
          return "周口市人民政府";
        case 85:
          return "河南省教育厅";
        case 86:
          return "河北省教育厅";
        case 87:
          return "安徽省教育厅";
        case 88:
          return "甘肃省教育厅";
        case 89:
          return "原航空工业部";
        case 90:
          return "吉林市人民政府";
        case 91:
          return "通化市人民政府";
        case 92:
          return "贵州省文化和旅游厅";
        case 93:
          return "重庆市经济和信息化委员会";
        case 94:
          return "广东省教育厅";
        case 95:
          return "陕西省教育厅";
        case 96:
          return "广西教育厅";
        case 101:
          return "总装备";
        case 102:
          return "宁夏回族自治区教育厅";
        case 103:
          return "内蒙古自治区教育厅";
        case 104:
          return "省交通运输厅";
        case 105:
          return "省农垦总局";
        case 107:
          return "市教育委员会";
        case 108:
          return "自治区教育厅";
        case 109:
          return "新疆维吾尔自治区教育厅";
        case 110:
          return "自治区农业农村厅";
        case 24:
          return "其他";
        default:
          return data;
      }
    },
    educationFilter(data) {
      switch (data) {
        case 0:
          return "不限";
        case 1:
          return "本科";
        case 2:
          return "高职";
        case 3:
          return "其他";
        default:
          return data;
      }
    },
  },
  mounted() { },
  methods: {

    showimg(item) {
      this.img = item;
      this.dialogVisible = true;
    },
    getUniversityInfo() {
      getUniversityInfo({ universityId: this.universityId }).then((res) => {
        if (res.code == 1) {
          this.schoolform = res.data || {};
          this.schoolform.collegeCharacter =
            this.schoolform.collegeCharacter.split(",");
          this.schoolform.albumPictures =
            this.schoolform.albumPictures.split(",");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.bigbox {
  width: 910px;
  box-sizing: border-box;

  .header {
    height: 80px;
    display: flex;
    box-sizing: border-box;
    margin: 20px 0px;

    .img {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      overflow: hidden;
    }

    .text {
      margin-top: 10px;

      span {
        width: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-right: 20px;
      }
    }

    .schoolname {
      font-size: 18px;
      height: 18px;
      line-height: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .bo {
      margin-top: 10px;

      //   max-block-size: 10px;
      span {
        display: inline-block;
      }

      .span {
        display: inline-block;
        width: 64px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 12px;
        margin-right: 10px;
      }

      .span1 {
        background-color: #fff9e0;
        color: #ef9a70;
      }

      .span2 {
        background-color: #e0ffe0;
        color: #65e365;
      }

      .span3 {
        background-color: #fff9e0;
        color: #ef9a70;
      }
    }
  }

  .content {
    width: 100%;
    font-size: 14px;
    text-indent: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }

  .smalltitle {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0px;

    .titleline {
      width: 8px;
      height: 26px;
      background: #1ab394;
      border-radius: 0px 4px 4px 0px;
    }

    .titletext {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      height: 26px;
      line-height: 26px;
      margin-left: 10px;
    }
  }

  .imglist {
    margin-top: 20px;

    .schoolimg {
      width: 220px;
      max-height: 120px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }

  .imglist .schoolimg:nth-child(4n) {
    margin-right: 0px;
  }
}
</style>