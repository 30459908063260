<template>
  <div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :header-cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        color: '#333333',
      }"
      :cell-style="{ 'text-align': 'center', color: '#666666' }"
    >
      <el-table-column prop="type" label="榜单名称"> </el-table-column>
      <el-table-column prop="ranking" label="排名"> </el-table-column>
      <el-table-column prop="year" label="数据更新年份">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUniversityRankingList } from "@/api/dataquery";
export default {
  data() {
    return {
      dto: {},
      tableData:　[],
    };
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
  },
  watch: {
    form: {
      immediate: true,
      handler(n, o) {
        this.dto = n;
        if (this.form.universityId) {
          this.getUniversityRankingList();
        }
      },
      deep: true,
    },
  },
  methods: {
    getUniversityRankingList() {
      getUniversityRankingList(this.dto).then((res) => {
        if (res.code == 1) {
            this.tableData = res.data || []
        }
      });
    },
  },
};
</script>