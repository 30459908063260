<template>
  <div>
    <el-tabs v-model="chooseActiveName">
      <el-tab-pane label="院校分数" name="first">
        <!-- 院校 -->
        <div v-for="(item, index) in tabs" :key="index + 'school'">
          <div class="smalltitle">
            <div class="titleline"></div>
            <div class="titletext">院校录取数据({{ item.label }})</div>
          </div>
          <div class="tabel">
            <el-tabs v-model="item.activeName">
              <el-tab-pane
                :label="item1.collegeName"
                :name="item1.universityId"
                v-for="(item1, index1) in item.schooltableData"
                :key="index1"
              >
                <el-table
                  :data="item1.data"
                  style="width: 100%"
                  stripe
                  :header-cell-style="{
                    'text-align': 'center',
                    'font-size': '14px',
                    color: '#333333',
                  }"
                  :cell-style="{ 'text-align': 'center', color: '#666666' }"
                >
                  <el-table-column prop="year" label="年份"> </el-table-column>
                  <el-table-column prop="admitBatch" label="批次">
                  </el-table-column>
                  <el-table-column
                    prop="scoreLowest"
                    label="最低分"
                  ></el-table-column>
                  <el-table-column label="最高分">
                    <template>
                      <div style="color: #666666">0</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="actualNum"
                    label="招生数"
                  ></el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="专业分数" name="second">
        <!-- 专业 -->
        <div v-for="(item, index) in tabs" :key="index + 'specialty'">
          <div class="smalltitle">
            <div class="titleline"></div>
            <div class="titletext">专业录取数据（{{ item.label }}）</div>
          </div>
          <div class="tabel">
            <el-table
              :data="item.specialtytableData"
              style="width: 100%"
              stripe
              :header-cell-style="{
                'text-align': 'center',
                'font-size': '14px',
                color: '#333333',
              }"
              :cell-style="{ 'text-align': 'center', color: '#666666' }"
            >
              <el-table-column prop="year" label="年份"> </el-table-column>
              <el-table-column prop="admitBatch" label="批次">
              </el-table-column>
              <el-table-column
                prop="scoreLowest"
                label="最低分"
              ></el-table-column>
              <el-table-column label="最高分">
                <template>
                  <div style="color: #666666">0</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="actualNum"
                label="招生数"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getUniversityAdmitData,
  getUniversitySpecialtyData,
  getOneProvince,
} from "@/api/dataquery";
export default {
  data() {
    return {
      chooseActiveName: "first",
      universityId: "",
      tabs: [],
      collegeName: "",
      tableData: [],
      form: {
        disciplineType: "0",
      },
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(n, o) {
        this.universityId = n;
      },
      deep: true,
    },
    name: {
      immediate: true,
      handler(n, o) {
        this.collegeName = n;
      },
      deep: true,
    },
  },
  created() {
    let student1 = sessionStorage.getItem("student") || {};
    let student = JSON.parse(student1);
    let year = new Date().getFullYear();
    if (
      new Date().getTime() <=
      new Date(new Date().getFullYear() + "/8/31").getTime()
    ) {
      year -= 1;
    }
    this.form.year = year;
    this.form.provinceId = student.province;
    this.getOneProvince();
  },
  mounted() {},
  methods: {
    //获取科别
    getOneProvince() {
      getOneProvince({
        year: this.form.year,
        areaProvinceId: this.form.provinceId,
      }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          if (data.pattern == 1) {
            this.tabs = [{ label: "不分文理", value: "0" }];
            this.activeName = "0";
            this.activeName1 = "0";
            this.getUniversityAdmitData();
            this.getUniversitySpecialtyData();
          }
          if (data.pattern == 2) {
            this.tabs = [
              { label: "物理类", value: "3" },
              { label: "历史类", value: "4" },
            ];
            this.activeName = "3";
            this.activeName1 = "3";
            this.getUniversityAdmitData();
            this.getUniversitySpecialtyData();
          }
          if (data.pattern == 3) {
            this.tabs = [
              { label: "理科", value: "1" },
              { label: "文科", value: "2" },
            ];
            this.getUniversityAdmitData();
            this.getUniversitySpecialtyData();
          }
        }
      });
    },
    // 院校录取数据
    getUniversityAdmitData() {
      this.tabs.forEach((item) => {
        getUniversityAdmitData({
          universityId: this.universityId,
          year: this.form.year,
          provinceId: this.form.provinceId,
          disciplineType: item.value - 0,
        }).then((res) => {
          if (res.code == 1) {
            item.schooltableData = res.data || [];
            item.activeName = item.schooltableData[0].universityId;
          }
        });
      });
    },
    //专业录取数据
    getUniversitySpecialtyData() {
      this.tabs.forEach((item) => {
        getUniversitySpecialtyData({
          universityId: this.universityId,
          year: this.form.year,
          provinceId: this.form.provinceId,
          disciplineType: item.value - 0,
        }).then((res) => {
          if (res.code == 1) {
            item.specialtytableData = res.data || [];
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.smalltitle {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px;
  .titleline {
    width: 8px;
    height: 26px;
    background: #1ab394;
    border-radius: 0px 4px 4px 0px;
  }
  .titletext {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    height: 26px;
    line-height: 26px;
    margin-left: 10px;
  }
}
.tabel {
  margin-bottom: 30px;
}
</style>